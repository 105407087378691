import React, { useEffect, useState } from 'react'
import { adminGetPostById } from 'store/AdminSlices/Admin';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';
import { Card, Grid } from '@mui/material';
import MDTypography from 'components/MDTypography';

const Post = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [post, setPost] = useState(null)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(adminGetPostById(id));
                // console.log("post", response.payload.result.post)
                // setPosts(response);
                setPost(response.payload.result.post);
            } catch (error) {
                // Handle any errors here
                console.error("Error fetching posts:", error);
            }
        };

        fetchData();
        // console.log("ASdfasdf", post)
    }, [dispatch, id]);

    return (
        <DashboardLayout>
            <DashboardNavbar absolute isMini />
            <MDBox my={3} pt={6}>
                <Grid container display="flex" alignItems="center" justifyContent="center">
                    <Grid item xs={12} lg={8}>
                        <Card style={{ padding: "2rem" }}>
                            {
                                post ? <>
                                    <Grid display="flex" >
                                        <Grid item xs={12} lg={4} >
                                            <MDTypography component="span" style={{ textAlign: "center" }}
                                                variant="body2" my={4}>
                                                {
                                                    post?.fileName ? <img src={`${process.env.REACT_APP_IMG_URL}/${post?.fileName}`} width={200} style={{ borderRadius: '12px' }} /> : ""
                                                }
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={12} lg={8}>
                                            <Grid>
                                                <Grid my={2}>
                                                    <MDTypography style={{ padding: "0", margin: '0', lineHeight: '0.5' }}> Title: </MDTypography>
                                                    <MDTypography component="span" style={{ fontSize: '14px' }}> {post?.title}
                                                    </MDTypography>
                                                </Grid>
                                                <Grid sx={{height:"120px", width:"100%",overflow:"auto" }}>
                                                    <MDTypography style={{ padding: "0", margin: '0', lineHeight: '1' }}> Description: </MDTypography>
                                                    <MDTypography component="span" style={{ fontSize: '14px' , wordBreak: "break-all", }}> {post?.description}
                                                    </MDTypography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </> : <Grid style={{ padding: "15px" }}>  </Grid>
                            }

                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    )
}

export default Post