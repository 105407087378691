import React, { useRef, useState } from "react";
import { Box, Card, Grid, Typography } from '@mui/material'
import { Field, Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import MDBox from 'components/MDBox'
import MDInput from 'components/MDInput'
import MDButton from "components/MDButton";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Icon from "@mui/material/Icon";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import PostImage from '../../assets/images/post-cover.png';
import './CreateTeam.css';
import Cropper from "../../layouts/utils/Cropper";

import { adminCreateTeam } from "../../store/AdminSlices/Admin";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

const CreateTeam = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showCroppedImage, setShowCroppedImage] = useState(null);
    const [profileImageSrc, setProfileImageSrc] = useState(null);
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [newsImg, setNewsImg] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        twitterLink: Yup.string().required('Link is required'),
        // Removed file validation
    });
    const initialValues = {
        name: '',
        twitterLink: '',
        file: null,
    };
    const onSubmit = async (values) => {
        if (selectedImage) {
            // console.log("image", selectedImage)
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('twitterLink', values.twitterLink);
            formData.append('file', newsImg);
            dispatch(adminCreateTeam(formData)).then((res)=>{
                console.log("res", res)
                if(res?.payload?.success == true){
                    navigate('/members')
                }
            })

        }
        else {
            // console.log("no image")
            let data = values;
            delete data.file;
            dispatch(adminCreateTeam(data)).then((res)=>{
                if(res?.payload?.success == true){
                    navigate('/members')
                }
            })

        }
    };

    const hiddenFileInput = useRef(null);




    const handleClose = () => setOpen(false);

    return (
        <div>
            <DashboardLayout>
                <DashboardNavbar absolute isMini />
                <MDBox my={3} pt={6}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ errors, touched, setFieldValue }) => {
                            return (
                                <Form>
                                    <MDBox mb={3}>
                                        <Grid container display="flex" alignItems="center" justifyContent="center">
                                            <Grid item xs={12}>
                                                <Card>
                                                    <Grid>
                                                        <Grid >
                                                            <Box>
                                                                <Grid
                                                                    container
                                                                    width="100%"
                                                                    display="flex"
                                                                    alignItems="center"
                                                                    justifyContent="space-between"
                                                                    py={4}
                                                                    px={4}

                                                                >
                                                                    <Grid item md={6} sm={6} lg={2}>
                                                                        <Box
                                                                            position="relative"
                                                                            // overflow="hidden"
                                                                            display="inline-block"
                                                                            width={200}
                                                                        // sx={{ cursor: "pointer"  }}
                                                                        >
                                                                            <Box
                                                                                component="img"
                                                                                src={
                                                                                    profileImageSrc && !showCroppedImage
                                                                                        ? profileImageSrc
                                                                                        : showCroppedImage
                                                                                            ? showCroppedImage
                                                                                            : PostImage
                                                                                }
                                                                                alt="uploadImg"
                                                                                sx={{
                                                                                    height: "200px",
                                                                                    width: "175px",
                                                                                    borderRadius: "10px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            />
                                                                            <input
                                                                                type="file"
                                                                                accept={"image/*"}
                                                                                style={{
                                                                                    opacity: 0,
                                                                                    position: "absolute",
                                                                                    top: 0,
                                                                                    left: 0,
                                                                                    width: "100%",
                                                                                    height: "100%",
                                                                                }}
                                                                                onChange={(event) => {
                                                                                    setSelectedImage(event?.currentTarget?.files[0]);
                                                                                    let file = event?.currentTarget?.files[0];
                                                                                    if (event.target.files.length === 1) {
                                                                                        setOpen(true);
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <ErrorMessage name="file" component="span" className="error" style={{ fontSize: '12px' }} />

                                                                        </Box>
                                                                        {open && (
                                                                            <Box sx={Boxstyle}>
                                                                                <Typography
                                                                                    style={{
                                                                                        color: "#333333",
                                                                                        fontFamily: "OpenSans",
                                                                                        fontSize: "20px",
                                                                                        width: "100%",
                                                                                    }}
                                                                                    pb={2}
                                                                                >
                                                                                    Edit News Image
                                                                                </Typography>
                                                                                <Cropper
                                                                                    name="profilePicture"
                                                                                    formik={setFieldValue}
                                                                                    onClose={handleClose}
                                                                                    setShowCroppedImage={setShowCroppedImage}
                                                                                    selectedImage={selectedImage}
                                                                                    setFile={setNewsImg}
                                                                                />
                                                                            </Box>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid xs={6} md={6} sm={6} lg={10}>
                                                                        <Grid mx={4} my={1}>
                                                                            <Grid container>
                                                                                <Field
                                                                                    type="text"
                                                                                    name="name"
                                                                                    as={MDInput}
                                                                                    label="Name"
                                                                                    fullWidth
                                                                                />
                                                                                <ErrorMessage name="name" component="span" className="error" style={{ fontSize: "12px" }} />
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid mx={4} my={1} >
                                                                            <Grid container>
                                                                                <Field
                                                                                    type="text"
                                                                                    name="twitterLink"
                                                                                    as={MDInput}
                                                                                    label="Link"
                                                                                    fullWidth
                                                                                    // multiline                                                                      fullWidth
                                                                                    component={MDInput}
                                                                                    // rows={4}
                                                                                />
                                                                                <ErrorMessage name="twitterLink" component="span" className="error" style={{ fontSize: "12px" }} />
                                                                            </Grid>
                                                                        </Grid>
                                                                            <Grid mx={4} my={1}>
                                                                                <Grid container display="flex" justifyContent="flex-end">
                                                                                    <MDButton type="submit" color="info">Submit</MDButton>
                                                                                </Grid>
                                                                            </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </Form>
                            );
                        }}
                    </Formik>
                </MDBox>
            </DashboardLayout>
        </div>
    )
}

const Boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "300px",
    height: "420px",
    border: "2px solid #1D9BF0",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    py: 2,
    px: 4,
    zIndex: "9999999999"
};

export default CreateTeam;
