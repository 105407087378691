import React, { useEffect, useRef, useState } from "react";
import { Box, Card, Grid, Switch, Typography } from '@mui/material'
import { Field, Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import MDBox from 'components/MDBox'
import MDInput from 'components/MDInput'
import MDButton from "components/MDButton";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Icon from "@mui/material/Icon";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

import PostImage from '../../assets/images/post-cover.png';
// import './AddAuthor.css';
// import {adminCreatePost} from '../../store/AdminSlices/Admin';
import { adminUpdatePost, adminGetPostById } from "../../store/AdminSlices/Admin";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MDTypography from "components/MDTypography";
import Cropper from "../../layouts/utils/Cropper";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";


const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    publishDate: Yup.string().required('Publish date is required')
});
const EditPost = () => {
    const { id } = useParams();
    const [post, setPost] = useState(null)
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [showCroppedImage, setShowCroppedImage] = useState(null);
    const [profileImageSrc, setProfileImageSrc] = useState(null);
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [newsImg, setNewsImg] = React.useState(null);
    const [open, setOpen] = React.useState(false);




    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(adminGetPostById(id));
                console.log("post", response.payload.result.post)
                // setPosts(response);
                setPost(response.payload.result.post);
            } catch (error) {
                // Handle any errors here
                console.error("Error fetching posts:", error);
            }
        };

        fetchData();
    }, [dispatch]);



    const initialValues = {
        title: post ? post?.title : '',
        description: post ? post?.description : "",
        file: "",
        featured: post ? post?.featured : "",
        publishDate: post ? dayjs(post.publishDate) : ""
    };
    const onSubmit = (values) => {
        console.log("publishDate",values.publishDate)
        if (selectedImage) {
            // console.log("image", selectedImage)
            const formData = new FormData();
            formData.append("postId", id)
            formData.append('title', values.title);
            formData.append('description', values.description);
            formData.append('file', newsImg);
            formData.append('publishDate', values.publishDate)
            dispatch(adminUpdatePost(formData)).then((res) => {
                // console.log("res",res?.payload.success);
                if (res.payload.success === true) {
                    navigate("/posts")
                }
            });

        }
        else {
            // console.log("no image")
            let data = values;
            data.postId = id
            delete data.file;
            dispatch(adminUpdatePost(data)).then((res) => {
                // console.log("res",res?.payload.success);
                if (res.payload.success === true) {
                    navigate("/posts")
                }
            });
        }

        // dispatch(adminCreatePost(values));
    };
    const [crop, setCrop] = useState(false);
    const [crope, setCrope] = useState();
    const hiddenFileInput = useRef(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    let formik;
    const handleClose = () => setOpen(false);

    // console.log("initial values :", post?.newsImage?.data)
    return (
        <div>
            <DashboardLayout>
                <DashboardNavbar absolute isMini />
                <MDBox my={3} pt={6}>

                    {
                        post ?
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {({  errors, touched, setFieldValue }) => {
                                    // Store the formik context in a variable for later use
                                    formik = { setFieldValue };
                                    return (
                                        <Form>
                                            <MDBox>
                                                <Grid container display="flex" alignItems="center" justifyContent="center">
                                                    <Grid item xs={12}>
                                                        <Card>
                                                            <Grid>
                                                                <Grid container
                                                                    width="100%"
                                                                    display="flex"
                                                                    alignItems="center"
                                                                    justifyContent="space-between"
                                                                    py={4}
                                                                    px={4}
                                                                >
                                                                    <Grid item xs={6} md={6} sm={6} lg={5}>
                                                                        <Box
                                                                            position="relative"
                                                                            // overflow="hidden"
                                                                            display="inline-block"
                                                                            width={400}
                                                                        // sx={{ cursor: "pointer"  }}
                                                                        >
                                                                            <Box
                                                                                component="img"
                                                                                src={
                                                                                    profileImageSrc && !showCroppedImage
                                                                                        ? profileImageSrc
                                                                                        : showCroppedImage
                                                                                            ? showCroppedImage
                                                                                            : post?.fileName ? `${process.env.REACT_APP_IMG_URL}/${post?.fileName}` : ""
                                                                                }
                                                                                alt="uploadImg"
                                                                                sx={{
                                                                                    height: "200px",
                                                                                    width: "400px",
                                                                                    borderRadius: "10px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            />
                                                                            <input
                                                                                type="file"
                                                                                accept={"image/*"}
                                                                                style={{
                                                                                    opacity: 0,
                                                                                    position: "absolute",
                                                                                    top: 0,
                                                                                    left: 0,
                                                                                    width: "100%",
                                                                                    height: "100%",
                                                                                    cursor: "pointer"
                                                                                }}
                                                                                onChange={(event) => {
                                                                                    setSelectedImage(event?.currentTarget?.files[0]);
                                                                                    let file = event?.currentTarget?.files[0];
                                                                                    if (event.target.files.length === 1) {
                                                                                        setOpen(true);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                        {open && (
                                                                            <Box sx={Boxstyle}>
                                                                                <Typography
                                                                                    style={{
                                                                                        color: "#333333",
                                                                                        fontFamily: "OpenSans",
                                                                                        fontSize: "20px",
                                                                                        width: "100%",
                                                                                    }}
                                                                                    pb={2}
                                                                                >
                                                                                    Edit News Image
                                                                                </Typography>
                                                                                <Cropper
                                                                                    component="post"
                                                                                    name="profilePicture"
                                                                                    formik={setFieldValue}
                                                                                    onClose={handleClose}
                                                                                    setShowCroppedImage={setShowCroppedImage}
                                                                                    selectedImage={selectedImage}
                                                                                    setFile={setNewsImg}
                                                                                />
                                                                            </Box>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item xs={6} md={6} sm={6} lg={7}>
                                                                        <Grid ml={4} my={0} mb={1} sx={{marginBottom:"15px"}}>
                                                                            <Field
                                                                                type="text"
                                                                                name="title"
                                                                                as={MDInput}
                                                                                label="Title"
                                                                                fullWidth
                                                                            />
                                                                            <ErrorMessage name="title" component="span" className="error" style={{ fontSize: "12px" }} />
                                                                        </Grid>
                                                                        <Grid ml={4} my={1} >
                                                                            <Field
                                                                                type="text"
                                                                                name="description"
                                                                                as={MDInput}
                                                                                label="Description"
                                                                                fullWidth
                                                                                multiline
                                                                                rows={4}
                                                                            />
                                                                            <ErrorMessage name="description" component="span" className="error" style={{ fontSize: "12px" }} />
                                                                        </Grid>
                                                                        <Grid ml={4} my={1}>
                                                                            <Grid container>
                                                                                <Box>
                                                                                    <LocalizationProvider style={{ marginBottom: "5px" }} dateAdapter={AdapterDayjs}  >
                                                                                        <DemoContainer components={["DatePicker"]}>
                                                                                            <Field
                                                                                            as={DatePicker}
                                                                                                label="Select Date"
                                                                                                disablePast
                                                                                                name="publishDate"
                                                                                                // value={post?.publishDate ? dayjs(post.publishDate) : null}
                                                                                                onChange={(newValue) => setFieldValue('publishDate', dayjs(newValue).format('YYYY-MM-DD'))}
                                                                                            />
                                                                                        </DemoContainer>
                                                                                    </LocalizationProvider>
                                                                                    <ErrorMessage name="publishDate" component="span" className="error" style={{ fontSize: "12px" }} />
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid ml={4} my={0}>
                                                                            <Grid container display="flex" flexDirection="column">
                                                                                <div>
                                                                                    <label style={{ fontSize: "12px", color: "#7b809a" }}>Featured</label>
                                                                                </div>
                                                                                <div>
                                                                                    <Field
                                                                                        type="checkbox"
                                                                                        name="featured"
                                                                                        as={Switch}
                                                                                    />
                                                                                    <ErrorMessage name="featured" component="span" className="error" style={{ fontSize: "12px" }} />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container display="flex" justifyContent="flex-end">
                                                                        <MDButton type="submit" color="info">Submit</MDButton>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </MDBox>
                                        </Form>
                                    );
                                }}
                            </Formik> : <Grid>
                                Loading...
                            </Grid>
                    }
                </MDBox>
            </DashboardLayout>
        </div>
    )
}


const Boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "300px",
    height: "420px",
    border: "2px solid #1D9BF0",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    py: 2,
    px: 4,
    zIndex: "9999999999"
};

export default EditPost