import Cropper from "react-easy-crop";
import React, { useState, useCallback } from "react";
import { Box, Slider, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getCroppedImg } from "./CanvasUtils";

const Demo = ({
  setShowCroppedImage,
  selectedImage,
  aspectRatio,
  onClose,
  formik,
  name,
  setFile,
  component
}) => {
  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: -20, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const theme = useTheme();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // console.log("component", component)

  

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      setShowCroppedImage(croppedImage);
      // Convert the cropped image data URL to a file
      const blob = await (await fetch(croppedImage)).blob();
      const file = new File([blob], "newImag.png", {
        type: blob.type,
      });
      setFile(file);
      // console.log("file:", file)

      //   formik.setFieldValue(name, file);
      onClose();
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels]);

  const handleReadFile = async (file) => {
    return await readFile(file);
  };

  React.useEffect(() => {
    const loadImageDataUrl = async () => {
      if (selectedImage) {
        const file = selectedImage;
        let imageDataUrl = await readFile(file);
        setImageSrc(imageDataUrl);
      }
    };

    loadImageDataUrl();
  }, [selectedImage]);

  const cropContainerStyle = {
    position: "relative",
    width: "100%",
    height: 200,
    background: "#e05528",
    [theme.breakpoints.up("sm")]: {
      height: 320,
    },
  };

  const cropButtonStyle = {
    flexShrink: 0,
    marginLeft: 16,
    color: "white"
  };

  const controlsStyle = {
    padding: "16px 16px 0px 16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  };

  const sliderContainerStyle = {
    display: "flex",
    flex: "1",
    alignItems: "center",
  };

  const sliderLabelStyle = {
    [theme.breakpoints.down("xs")]: {
      minWidth: 65,
    },
  };

  const sliderStyle = {
    padding: "22px 0px",
    marginLeft: 16,
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      margin: "0 16px",
    },
  };

  return (
    <>
      {imageSrc ? (
        <Box>
          <div style={cropContainerStyle}>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={component === 'post' ? 50 / 30 : 7 / 7}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>

          <Box style={controlsStyle}>
            <Typography
              style={{
                color: "#636363",
                fontFamily: "OpenSans",
                fontSize: "12px",
                width: "100%",
                textAlign: "center",
              }}
            >
              Scroll or drag to reposition picture
            </Typography>
            <Box style={sliderContainerStyle}>
              <Typography variant="overline" style={sliderLabelStyle}>
                Zoom
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={5}
                step={0.1}
                aria-labelledby="Zoom"
                style={sliderStyle}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </Box>
            <Button
              onClick={showCroppedImage}
              variant="contained"

              style={cropButtonStyle}
            >
              Crop
            </Button>
          </Box>
        </Box>
      ) : (
        <Typography variant="body1">Please select an image.</Typography>
      )}
    </>
  );
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader?.addEventListener("load", () => resolve(reader?.result), false);
    reader?.readAsDataURL(file);
  });
}

export default Demo;
