import { useState } from "react";
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from "yup";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import sugar from "assets/images/sugar.png";
import { useNavigate } from "react-router-dom";
// import { fetchData } from '../../../store/AdminSlices/Admin';
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
// import { adminSignIn } from "../../../store/AdminSlices/Admin";
import { adminForgetPassword } from "../../store/AdminSlices/Admin";



function ForgetPassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginData = useSelector((state) => state);
    const initialValues = {
        email: "",
        // password: "",
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email address").required("Email is required"),
        // password: Yup.string().required("Password is required"),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        const val = await dispatch(adminForgetPassword(values));
        // console.log("dfasdf",val);
        if(val.payload.success == true) {
            if(val.payload.success == true){
                navigate('/reset-password',{
                    state: {
                      email:values.email
                    }
                  });
            }
        }


    };

    const [rememberMe, setRememberMe] = useState(false);
    const handleSetRememberMe = () => setRememberMe(!rememberMe);

    return (
        <BasicLayout image={sugar}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Forget Password
                    </MDTypography>
                    {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid> */}
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {(formik) => (
                            <Form>
                                <MDBox mb={2}>
                                    <Field
                                        type="email"
                                        name="email"
                                        label="Email"
                                        fullWidth
                                        component={MDInput}
                                    />
                                    <ErrorMessage name="email" component="span" className="error" style={{ fontSize: "12px" }} />
                                </MDBox>
                                <MDBox display="flex" alignItems="center" ml={-1}>
                                    {/* ... "Remember Me" Switch ... */}
                                </MDBox>
                                <MDBox mt={4} mb={1}>
                                    <MDButton
                                        type="submit"
                                        variant="gradient"
                                        color="info"
                                        fullWidth
                                        disabled={formik.isSubmitting}
                                    >
                                        Send OTP
                                    </MDButton>
                                </MDBox>
                                {/* <MDBox mt={3} mb={1} textAlign="center">
                                    <MDTypography component="span" style={{ fontSize: "12px" }} onClick={()=>{navigate("/reset-password")}}>
                                        Reset Password
                                    </MDTypography>
                                </MDBox> */}
                            </Form>
                        )}
                    </Formik>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}



export default ForgetPassword;
