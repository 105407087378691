import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import TeamsTable from "examples/TeamsTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { adminGetTeams, adminDeleteTeam } from "../../store/AdminSlices/Admin";
import {setSharedState } from "../../store/AdminSlices/Functions";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import Swal from "sweetalert2";

function Teams() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [posts, setPosts] = useState([]); // State to store posts
  const sharedState = useSelector((state) => state?.sharedReducer?.sharedState);
  const [refresh, setRefresh] = useState(false);
  const [totalDoc, setTotalDoc] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDataPage, setCurrentDataPage] = useState(0) // State to store posts

  const handleAddNewAuthor = () => {
    navigate('/create-member');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(adminGetTeams(currentPage));
        // console.log("ASdfsadf", response?.payload?.result?.team?.docs)
        setPosts(response.payload.result.team.docs);
        setTotalDoc(response?.payload?.result?.team?.totalDocs);
        // console.log("--teams", response?.payload?.result);
        setTotalPages(response?.payload?.result?.team?.totalPages)
        setCurrentDataPage(response?.payload?.result?.team?.currentPage)
      } catch (error) {
        // Handle any errors here
        console.error("Error fetching posts:", error);
      }
    };

    fetchData();
  }, [dispatch, sharedState, currentPage]);

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
  const handleDelete = (id) => {
    // console.log("delete", id);
    Swal.fire({
      title: 'Are you sure you want to delete the member?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(adminDeleteTeam(id));
        dispatch(setSharedState());
      } else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  const Job = ({ title, description, data }) => (
    <MDBox>
      <MDButton onClick={()=>handleDelete(data)} variant="text" color="error" ><Icon>delete</Icon>&nbsp; Delete </MDButton>
      <MDButton onClick={()=>{navigate(`/edit-member/${data}`)}}><Icon>edit</Icon>&nbsp;Edit </MDButton>
      <MDButton onClick={()=>{navigate(`/member/${data}`)}}><Icon>visibility</Icon>&nbsp;View </MDButton>
    </MDBox>
    // <MDBox lineHeight={1} textAlign="left">
    //   <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
    //     <MDButton>{title}</MDButton>
    //   </MDTypography>
    //   <MDTypography variant="caption">{description}</MDTypography>
    // </MDBox>
  );

  // const transformedData = posts?.map(item => ({
  //   name: <Author image={`${process.env.REACT_APP_IMG_URL}/${item.fileName}`} name={item.name} email={item.twitterLink} />,
  //   function: <Job title="Manager" description="Organization" data={item?._id} />,
  //   // status: (
  //   //   <MDBox ml={-1}>
  //   //     <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
  //   //   </MDBox>
  //   // )
  // }));

  // console.log("postes:", posts)

  const transformedData = posts?.map(item => ({
    id: item._id,
    file: item.fileName,
    name: item.name,
    email:item?.twitterLink

  }));




  const tableData = {
    columns: [
      {
        Header: "Team Members",
        accessor: "name",
      },
      // {
      //   Header: "Title",
      //   accessor: "link",
      // },
      // {
      //   Header: "Action",
      //   accessor: "function"
      // }
      // Add more columns as needed
    ],
    rows: transformedData,
  };




  const handlePreviousPage = () => {
    // console.log("previous tesm");
    setCurrentPage(currentPage - 1);
    setRefresh(!refresh)
  }
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    // console.log("next page")
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Team Members
                </MDTypography>
              </MDBox>
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography variant="h6" fontWeight="medium">

                </MDTypography>
                <MDButton variant="gradient" color="dark" onClick={handleAddNewAuthor}>
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp;add new member
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                {/* Render your DataTable component here */}
                <TeamsTable
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25],
                  }}
                  HandlePreviousPage={handlePreviousPage}
                  handleNextPage={handleNextPage}
                  currentDataPage={currentDataPage}
                  totalDoc={totalDoc}
                  totalPages={totalPages}
                  canSearch={true}
                  showTotalEntries={true}
                  table={tableData}
                  pagination={{ variant: "gradient", color: "info" }}
                  isSorted={true}
                  noEndBorder={false}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Teams;
