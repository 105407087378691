
import { useState, useEffect, useMemo } from "react";
import { Provider } from 'react-redux';
import store from './store/store';


import { Routes, Route, useNavigate, useLocation, Navigate } from "react-router-dom";


import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";


import MDBox from "components/MDBox";


import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";


import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";


import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import SignIn from "layouts/authentication/sign-in";



import routes from "routes";


import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";


import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import Otp from "layouts/authentication/otp/Otp";
import ProtectedRoute from "./routeprotection/RouteProtection";
import Tables from "layouts/tables";
import { verifyLogin } from "services/Admin";
import LoginProtected from "routeprotection/LoginProtected";
import Billing from "layouts/billing";
import AddAuthor from "layouts/add-author/AddAuthor";
import EditPost from "layouts/EditPost/EditPost";
import Post from "layouts/post/Post";
import CreateTeam from "layouts/create-team/CreateTeam";
import Teams from "layouts/teams/Teams";
import Team from "layouts/team/Team";
import EditTeam from "layouts/authentication/editteam/EditTeam";
import ForgetPassword from "layouts/ForgetPassword/Forgetpassword";
import ResetPassword from "layouts/ResetPassword/ResetPassword";
import Contracts from "layouts/contracts/Contracts";
import AddContract from "layouts/add-contract/AddContract";
import Contract from "layouts/contract/Contract";
import EditContract from "layouts/EditContract/EditContract";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate()

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const token = localStorage.getItem("sugarToken");

  // const getRoutes = (allRoutes) => {
  //   if (!token) {
  //     // Token doesn't exist, navigate to "/authentication/sign-in"
  //     return [
  //       <Route exact path="/authentication/sign-in" element={<SignIn />} key={1} />,
  //       <Route exact path="/authentication/verify-login" element={<Otp />} key={2} />
  //     ];
  //   }
  //   else {
  //     return allRoutes.map((route) => {
  //       if (route.collapse) {
  //         // return {
  //         //   ...route,
  //         //   collapse: getRoutes(route.collapse),
  //         // };
  //       }

  //       if (route.route) {
  //         return <Route exact path={route.route} element={route.component} key={route.key} />;
  //       }

  //       return null;
  //     });
  //   }

  // };

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <Provider store={store}>
      <CacheProvider value={rtlCache}>
        <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName="Material Dashboard 2"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
              {configsButton}
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            <Route path="/authentication/verify-login" element={<LoginProtected Component={Otp} />} />
            <Route path="/authentication/sign-in" element={<LoginProtected Component={SignIn} />} />
            <Route path="/posts" element={<ProtectedRoute Component={Tables} />} />
            <Route path="/add-post" element={<ProtectedRoute Component={AddAuthor} />} />
            <Route path="/edit-post/:id" element={<ProtectedRoute Component={EditPost} />} />
            <Route path="/billing" element={<ProtectedRoute Component={Billing} />} />
            <Route path="/post/:id" element={<ProtectedRoute Component={Post} />} />
            <Route path="/create-member" element={<ProtectedRoute Component={CreateTeam} />} />
            <Route path="/members" element={<ProtectedRoute Component={Teams} />} />
            <Route path="/member/:id" element={<ProtectedRoute Component={Team} />} />
            <Route path="/edit-member/:id" element={<ProtectedRoute Component={EditTeam} />} />
            <Route path="/forget-password" element={<ProtectedRoute Component={ForgetPassword} />} />
            <Route path="/reset-password" element={<LoginProtected Component={ResetPassword} />} />
            <Route path="/contracts" element={<ProtectedRoute Component={Contracts} />} />
            <Route path="/add-contract" element={<ProtectedRoute Component={AddContract} />} />
            <Route path="/contract/:id" element={<ProtectedRoute Component={Contract} />} />
            <Route path="/edit-contract/:id" element={<ProtectedRoute Component={EditContract} />} />

            <Route path="*" element={<Navigate to="/posts" />} />
          </Routes>
        </ThemeProvider>
      </CacheProvider>
    </Provider>
  ) : (
    <Provider store={store}>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Sugar Heads Dashboard"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          <Route path="/authentication/verify-login" element={<LoginProtected Component={Otp} />} />
          <Route path="/authentication/sign-in" element={<LoginProtected Component={SignIn} />} />
          <Route path="/posts" element={<ProtectedRoute Component={Tables} />} />
          <Route path="/add-post" element={<ProtectedRoute Component={AddAuthor} />} />
          <Route path="/edit-post/:id" element={<ProtectedRoute Component={EditPost} />} />
          <Route path="/billing" element={<ProtectedRoute Component={Billing} />} />
          <Route path="/post/:id" element={<ProtectedRoute Component={Post} />} />
          <Route path="/create-member" element={<ProtectedRoute Component={CreateTeam} />} />
          <Route path="/members" element={<ProtectedRoute Component={Teams} />} />
          <Route path="/member/:id" element={<ProtectedRoute Component={Team} />} />
          <Route path="/edit-member/:id" element={<ProtectedRoute Component={EditTeam} />} />
          <Route path="/forget-password" element={<LoginProtected Component={ForgetPassword} />} />
          <Route path="/reset-password" element={<LoginProtected Component={ResetPassword} />} />
          <Route path="/contracts" element={<ProtectedRoute Component={Contracts} />} />
          <Route path="/add-contract" element={<ProtectedRoute Component={AddContract} />} />
          <Route path="/contract/:id" element={<ProtectedRoute Component={Contract} />} />
          <Route path="/edit-contract/:id" element={<ProtectedRoute Component={EditContract} />} />

          {/* {getRoutes(routes)} */}
          <Route path="*" element={<Navigate to="/posts" />} />
        </Routes>
      </ThemeProvider>
    </Provider>
  );
}
