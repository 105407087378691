import React, { useEffect, useState } from 'react'
import { adminGetTeamById } from 'store/AdminSlices/Admin';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';
import { Card, Grid } from '@mui/material';
import MDTypography from 'components/MDTypography';

const Team = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [post, setPost] = useState(null)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(adminGetTeamById(id));
                // console.log("post-----", response?.payload?.result?.team)
                // setPosts(response);
                setPost(response?.payload?.result?.team);
            } catch (error) {
                // Handle any errors here
                console.error("Error fetching posts:", error);
            }
        };

        fetchData();
    }, [dispatch, id]);


    // console.log("ASdfasdf", post?.filePath)


    return (
        <DashboardLayout>
            <DashboardNavbar absolute isMini />
            <MDBox my={3} pt={6}>
                <Grid container display="flex" alignItems="center" justifyContent="center">
                    <Grid item xs={12} lg={8}>
                        <Card style={{ padding: "2rem" }}>
                            {
                                post ? <>
                                    <Grid display="flex">
                                        <Grid item xs={12} lg={4}>
                                            <MDTypography component="span" style={{ textAlign: "center" }}
                                                variant="body2" my={4}>
                                                {
                                                    post?.fileName ? <img src={`${process.env.REACT_APP_IMG_URL}/${post?.fileName}`} style={{ borderRadius: '12px' }} width={200} /> : ""
                                                }
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={12} lg={8}>
                                            <Grid my={2}>
                                                <MDTypography style={{ padding: "0", margin: '0', lineHeight: '0.5' }}> Name: </MDTypography>
                                                <MDTypography component="span" style={{ fontSize: '14px' }}> {post?.name}
                                                </MDTypography>
                                            </Grid>
                                            <Grid>
                                                <MDTypography style={{ padding: "0", margin: '0', lineHeight: '0.5' }}> Link: </MDTypography>
                                                <MDTypography component="p" style={{ display: "block", fontSize: '14px', wordBreak: "break-all" }}> {post?.twitterLink}
                                                </MDTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </> : <Grid style={{ padding: "15px" }}>  </Grid>
                            }

                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    )
}

export default Team