import axios from "axios";
// require('dotenv').config();


export const signIn = async (credentials) => {
  return await axios.post(`${process.env.REACT_APP_API_URL}/login`, credentials);
}

export const verifyLogin = async (credentials) => {
  return await axios.post(`${process.env.REACT_APP_API_URL}/verify-login`, credentials);
}

export const forgetPassword = async (credentials) => {
  return await axios.post(`${process.env.REACT_APP_API_URL}/forget-password`, credentials);
}

export const resetPassword = async (credentials) => {
  return await axios.post(`${process.env.REACT_APP_API_URL}/restore-password`, credentials);
}

export const createPost = async (credentials) => {
  const token = localStorage.getItem('sugarToken')
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/post/create`,
      credentials,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      }
    );
    return response;
    // return await axios.post('http://localhost:5001/api/admin/post/create', credentials)
  } catch (error) {
    throw error; // You can handle errors in the calling code
  }
}


export const getPosts = async (credentials) => {
  const token = localStorage.getItem('sugarToken');
  // console.log("token:", token)
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/post/all?page=${credentials}&limit=5`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      }
    );
    return response;
    // return await axios.post('http://localhost:5001/api/admin/post/create', credentials)
  } catch (error) {
    throw error; // You can handle errors in the calling code
  }
}


export const getTeams = async (credentials) => {
  const token = localStorage.getItem('sugarToken');
  // console.log("token:", token)
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/team/all?page=${credentials}&limit=5`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      }
    );
    return response;
    // return await axios.post('http://localhost:5001/api/admin/post/create', credentials)
  } catch (error) {
    throw error; // You can handle errors in the calling code
  }
}








export const deletePost = async (id) => {
  const token = localStorage.getItem('sugarToken')
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/post/${id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}



export const updatePost = async (data) => {
  const token = localStorage.getItem('sugarToken')
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/post/update`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateTeam = async (data) => {
  const token = localStorage.getItem('sugarToken')
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/team/update`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}


export const deleteTeam = async (id) => {
  const token = localStorage.getItem('sugarToken')
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/team/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}


export const getPostById = async (id) => {
  const token = localStorage.getItem('sugarToken')
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/post/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export const getTeamById = async (id) => {
  const token = localStorage.getItem('sugarToken')
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/team/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export const createTeam = async (credentials) => {
  const token = localStorage.getItem('sugarToken')
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/team/create`,
      credentials,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      }
    );
    return response;
    // return await axios.post('http://localhost:5001/api/admin/post/create', credentials)
  } catch (error) {
    throw error; // You can handle errors in the calling code
  }
}

export const getAllContracts = async (credentials) => {
  const token = localStorage.getItem('sugarToken');
  // console.log("asdf", token)
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/contract/all?page=${credentials}&limit=5`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      }
    );
    return response;
    // return await axios.post('http://localhost:5001/api/admin/post/create', credentials)
  } catch (error) {
    throw error; // You can handle errors in the calling code
  }
}


export const createContract = async (credentials) => {
  const token = localStorage.getItem('sugarToken')
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/contract/create`,
      credentials,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      }
    );
    return response;
  } catch (error) {
    throw error; // You can handle errors in the calling code
  }
}


export const deleteContract = async (credentials) => {
  const token = localStorage.getItem('sugarToken')
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/contract/${credentials}`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      }
    );
    return response;
    // return await axios.post('http://localhost:5001/api/admin/post/create', credentials)
  } catch (error) {
    throw error; // You can handle errors in the calling code
  }
}




export const contractById = async (credentials) => {
  const token = localStorage.getItem('sugarToken');
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/contract/${credentials}`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      }
    )
    return response;

  }
  catch (error) {
    throw error; // You can handle errors in the calling code
  }
}


export const updateContractById = async (credentials) => {
  const token = localStorage.getItem('sugarToken');
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/contract/update`,
      credentials,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      }
    )
    return response;

  }
  catch (error) {
    throw error; // You can handle errors in the calling code
  }
}



// export const getUsers = async (filters) => {
//   const qeuryParams = reArangeFilters(filters)
//   return await axios.get('/users' + qeuryParams);
// }