

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useDispatch, useSelector } from "react-redux";
import { adminDeletePost } from "../../../../store/AdminSlices/Admin";
import {setSharedState } from "../../../../store/AdminSlices/Functions"

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function Bill({ title, description, id,  noGutter, featured }) {
  // console.log("featured:", featured)
  const functionState = useSelector((state) => state.functions);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;


  const postEdit = (id) => {
    // console.log("edit", id);
    navigate(`/edit-post/${id}`)
  }

  const viewPost = (id)=>{
    navigate(`/post/${id}`)
  }


  
 
  const handleDelete = (id) => {
    // console.log("delete", id);
    // reloadPage();
    Swal.fire({
      title: 'Are you sure you want to delete the post?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(adminDeletePost(id));
        dispatch(setSharedState());
        
      } else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
      mx={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
          
        >
          <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {title} 
          </MDTypography>

          <MDBox  display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <MDBox mr={1}>
              <MDButton onClick={() => handleDelete(id)} variant="text" color="error">
                <Icon>delete</Icon>&nbsp;delete
              </MDButton>
            </MDBox>
            <MDButton onClick={() => postEdit(id)} variant="text" color={darkMode ? "white" : "dark"}>
              <Icon>edit</Icon>&nbsp;edit
            </MDButton>
            <MDButton onClick={() => viewPost(id)} variant="text" color={darkMode ? "white" : "dark"}>
              <Icon>visibility</Icon>&nbsp;view
            </MDButton>
          </MDBox>
        </MDBox>
        {/* <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Title:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
              {company}
            </MDTypography>
          </MDTypography>
        </MDBox> */}
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Description:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {description}
            </MDTypography>
          </MDTypography>
        </MDBox>
        {
          featured == true ?  <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            <MDTypography variant="caption" fontWeight="medium">
              Featured
            </MDTypography>
          </MDTypography>
        </MDBox> :""
        }
        {/* <MDTypography variant="caption" color="text">
          VAT Number:&nbsp;&nbsp;&nbsp;
          <MDTypography variant="caption" fontWeight="medium">
            {vat}
          </MDTypography>
        </MDTypography> */}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
Bill.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  featured:PropTypes.bool
  // email: PropTypes.string.isRequired,
  // vat: PropTypes.string.isRequired,
  // noGutter: PropTypes.bool,
};

export default Bill;
