import { forwardRef } from "react";
import PropTypes from "prop-types";
import MDInputRoot from "components/MDInput/MDInputRoot";

const MDInput = forwardRef(({ error, success, disabled, field, ...rest }, ref) => (
  <MDInputRoot
    {...field}
    {...rest}
    ref={ref}
    ownerState={{ error, success, disabled }}
  />
));

MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  field: PropTypes.object.isRequired, // Formik field object
};

export default MDInput;
