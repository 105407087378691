import { Card, Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react';
import { adminGetContractById } from "../../store/AdminSlices/Admin"
import MDTypography from 'components/MDTypography';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Contract = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [contract, setContract] = useState(null)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(adminGetContractById(id));
                // console.log("post", response.payload.result.contract)
                // setPosts(response);
                setContract(response.payload.result.contract);
            } catch (error) {
                // Handle any errors here
                console.error("Error fetching posts:", error);
            }
        };

        fetchData();
        // console.log("ASdfasdf", response.payload.result.contract)
    }, [dispatch, id]);
    return (
        <DashboardLayout>
            <DashboardNavbar absolute isMini />
            <MDBox my={3} pt={6}>
                <Grid container display="flex" alignItems="center" justifyContent="center">
                    <Grid item xs={12} lg={8}>

                        <Card style={{ padding: "2rem" }}>
                            {
                                contract ? <>
                                    <Grid display="flex" >
                                        <Grid item xs={12} lg={4} >
                                            <MDTypography component="span" style={{ textAlign: "center" }}
                                                variant="body2" my={4}>
                                                {
                                                    contract?.fileName ?
                                                        <a href={`${process.env.REACT_APP_FILE_URL}/${contract?.fileName}`} target="_blank" rel="noopener noreferrer">
                                                            <span>Download File</span>
                                                        </a>
                                                        // <input disabled type="file" name={contract?.fileName} src={`${process.env.REACT_APP_FILE_URL}/${contract?.fileName}`} />
                                                        : ""
                                                }
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={12} lg={8}>
                                            <Grid>
                                                <Grid my={2}>
                                                    <MDTypography style={{ padding: "0", margin: '0', lineHeight: '0.5' }}> Name: </MDTypography>
                                                    <MDTypography component="span" style={{ fontSize: '14px' }}> {contract?.name}
                                                    </MDTypography>
                                                </Grid>
                                                <Grid sx={{ height: "130px", width: "100%", overflow: "auto" }}>
                                                    <MDTypography style={{ padding: "0", margin: '0', lineHeight: '1' }}> Address: </MDTypography>
                                                    <MDTypography component="span" style={{ fontSize: '14px', wordBreak: "break-all", }}> {contract?.address}
                                                    </MDTypography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </> : <Grid style={{ padding: "15px" }}>  </Grid>
                            }

                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    )
}

export default Contract