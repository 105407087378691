import React, { useRef, useState } from "react";
import { Box, Card, Grid, Switch, TextField, Typography } from '@mui/material'
import { Field, Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import MDBox from 'components/MDBox'
import MDInput from 'components/MDInput'
import MDButton from "components/MDButton";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Icon from "@mui/material/Icon";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import PostImage from '../../assets/images/post-cover.png';
import './AddAuthor.css';
import Cropper from "../../layouts/utils/Cropper";

import { adminCreatePost } from "../../store/AdminSlices/Admin";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";



const AddAuthor = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const posts = useSelector((state)=>state)

    const [showCroppedImage, setShowCroppedImage] = useState(null);
    const [profileImageSrc, setProfileImageSrc] = useState(null);
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [newsImg, setNewsImg] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [value, setValue] = useState(null);

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        description: Yup.string().required('Description is required'),
        publishDate: Yup.string()
            .required('Publish date is required')
    });
    const initialValues = {
        title: '',
        description: '',
        file: null,
        featured: false,
        publishDate: null || ''
    };
    const onSubmit = async (values) => {
        if (selectedImage) {
            // console.log("image", selectedImage)
            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('description', values.description);
            formData.append('file', newsImg);
            formData.append('featured', values.featured)
            formData.append('publishDate', values.publishDate)
            dispatch(adminCreatePost(formData)).then((res) => {
                // console.log("res",res )
                if (res?.payload?.success == true) {
                    navigate('/posts')
                }
            })
        }
        else {
            // console.log("no image")
            let data = values;
            delete data.file;
            dispatch(adminCreatePost(data)).then((res) => {
                // console.log("res",res )
                if (res?.payload?.success == true) {
                    navigate('/posts')
                }
            });
        }
    };

    const hiddenFileInput = useRef(null);




    const handleClose = () => setOpen(false);

    return (
        <div>
            <DashboardLayout>
                <DashboardNavbar absolute isMini />
                <MDBox my={3} pt={6}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ errors, touched, setFieldValue }) => {
                            return (
                                <Form>
                                    <MDBox >
                                        <Grid
                                            container display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item xs={12}>
                                                <Card>
                                                    <Grid>
                                                        <Grid >
                                                            <Grid >
                                                                <Grid
                                                                    container
                                                                    width="100%"
                                                                    display="flex"
                                                                    alignItems="center"
                                                                    // justifyContent="space-between"
                                                                    py={4}
                                                                    px={4}
                                                                >
                                                                    <Grid item xs={6} md={6} sm={6} lg={5}>
                                                                        <Box
                                                                            position="relative"
                                                                            // overflow="hidden"
                                                                            display="inline-block"
                                                                            width={400}
                                                                        // sx={{ cursor: "pointer"  }}
                                                                        >
                                                                            <Box
                                                                                component="img"
                                                                                src={
                                                                                    profileImageSrc && !showCroppedImage
                                                                                        ? profileImageSrc
                                                                                        : showCroppedImage
                                                                                            ? showCroppedImage
                                                                                            : PostImage
                                                                                }
                                                                                alt="uploadImg"
                                                                                sx={{
                                                                                    height: "200px",
                                                                                    width: "400px",
                                                                                    borderRadius: "15px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            />
                                                                            <input
                                                                                type="file"
                                                                                accept={"image/*"}
                                                                                style={{
                                                                                    opacity: 0,
                                                                                    position: "absolute",
                                                                                    top: 0,
                                                                                    left: 0,
                                                                                    width: "100%",
                                                                                    height: "100%",
                                                                                    cursor: "pointer"
                                                                                }}
                                                                                onChange={(event) => {
                                                                                    setSelectedImage(event?.currentTarget?.files[0]);
                                                                                    let file = event?.currentTarget?.files[0];
                                                                                    if (event.target.files.length === 1) {
                                                                                        setOpen(true);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                        {open && (
                                                                            <Box sx={Boxstyle}>
                                                                                <Typography
                                                                                    style={{
                                                                                        color: "#333333",
                                                                                        fontFamily: "OpenSans",
                                                                                        fontSize: "20px",
                                                                                        width: "100%",
                                                                                    }}
                                                                                    pb={2}
                                                                                >
                                                                                    Edit New Image
                                                                                </Typography>
                                                                                <Cropper
                                                                                    component="post"
                                                                                    name="profilePicture"
                                                                                    formik={setFieldValue}
                                                                                    onClose={handleClose}
                                                                                    setShowCroppedImage={setShowCroppedImage}
                                                                                    selectedImage={selectedImage}
                                                                                    setFile={setNewsImg}
                                                                                />
                                                                            </Box>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid xs={6} md={6} sm={6} lg={7}>
                                                                        <Grid ml={4} my={0} mb={1} sx={{marginBottom:"15px"}}>
                                                                            <Grid container>
                                                                                <Field
                                                                                    type="text"
                                                                                    name="title"
                                                                                    label="Title"
                                                                                    fullWidth
                                                                                    component={MDInput}
                                                                                />
                                                                                <ErrorMessage name="title" component="span" className="error" style={{ fontSize: "12px" }} />
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid ml={4} my={0} >
                                                                            <Grid container>
                                                                                <Field
                                                                                    type="text"
                                                                                    name="description"
                                                                                    label="Description"
                                                                                    multiline fullWidth
                                                                                    component={MDInput}
                                                                                    rows={4}

                                                                                />
                                                                                <ErrorMessage name="description" component="span" className="error" style={{ fontSize: "12px" }} />
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid ml={4} my={0}>
                                                                            <Grid container>
                                                                                <Box>
                                                                                    <LocalizationProvider style={{ marginBottom: "5px" }} dateAdapter={AdapterDayjs}  >
                                                                                        <DemoContainer components={["DatePicker"]}>
                                                                                            <DatePicker
                                                                                                label="Select Date"
                                                                                                disablePast
                                                                                                name="publishDate"
                                                                                                // value={values.publishDate}
                                                                                                onChange={(newValue) => setFieldValue('publishDate', dayjs(newValue).format('YYYY-MM-DD'))}
                                                                                            />
                                                                                        </DemoContainer>
                                                                                    </LocalizationProvider>
                                                                                    <ErrorMessage name="publishDate" component="span" className="error" style={{ fontSize: "12px" }} />
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid ml={4} my={0}>
                                                                            <Grid container display="flex" flexDirection="column">
                                                                                <div>
                                                                                    <label style={{ fontSize: "12px", color: "#7b809a" }}>Featured</label>
                                                                                </div>
                                                                                <div>
                                                                                    <Field
                                                                                        type="checkbox"
                                                                                        name="featured"
                                                                                        as={Switch}
                                                                                    />
                                                                                    <ErrorMessage name="featured" component="span" className="error" style={{ fontSize: "12px" }} />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid ml={4} my={1}>
                                                                            <Grid container display="flex" justifyContent="flex-end">
                                                                                <MDButton variant="gradient" type="submit" color="info" >Submit</MDButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {/* <ErrorMessage name="file" component="span" className="error" style={{ fontSize: '12px' }} /> */}

                                                    </Grid>

                                                    <Grid>


                                                    </Grid>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </Form>
                            );
                        }}
                    </Formik>
                </MDBox>
            </DashboardLayout>
        </div>
    )
}

const Boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "300px",
    height: "420px",
    border: "2px solid #1D9BF0",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    py: 2,
    px: 4,
    zIndex: "9999999999"
};





export default AddAuthor;
