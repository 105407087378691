// store.js

import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux'; // Import combineReducers from Redux
import  adminReducer from './AdminSlices/Admin';
import sharedReducer  from './AdminSlices/Functions'

// Combine your adminSlices into a single reducer
const rootReducer = combineReducers({
  admin: adminReducer,
  sharedReducer : sharedReducer ,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
