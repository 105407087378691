import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { adminGetPosts } from "../../store/AdminSlices/Admin";
import MDPagination from "components/MDPagination";

function Tables() {
  const navigate = useNavigate();
  const sharedState = useSelector((state) => state?.sharedReducer?.sharedState);
  const dispatch = useDispatch();
  const [posts, setPosts] = useState([]); // State to store posts
  const [refresh, setRefresh] = useState(false);
  const [totalDoc, setTotalDoc] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDataPage, setCurrentDataPage] = useState(0);
  // console.log("function------",sharedState)

  const handleAddNewAuthor = () => {
    navigate('/add-post');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(adminGetPosts(currentPage));
        setPosts(response.payload.result.posts.docs);
        setTotalDoc(response?.payload?.result?.posts?.totalDocs);
        setTotalPages(response?.payload?.result?.posts?.totalPages)
        setCurrentDataPage(response?.payload?.result?.posts?.currentPage)
        // console.log("refresh",response?.payload?.result?.posts);
      } catch (error) {
        // Handle any errors here
        console.error("Error fetching posts:", error);
      }
    };
    // console.log("refresh")
    fetchData();
  }, [dispatch, sharedState, currentPage]);



  const transformedData = posts?.map(item => ({
    id: item._id,
    title: item.title,
    description: item.description,
    featured:item?.featured

  }));



  const tableData = {
    columns: [

      {
        Header: "Roadmap Posts",
        accessor: "title",
      },
      // Add more columns as needed
    ],
    rows: transformedData,
  };

  


  const handlePreviousPage = () => {
    // console.log("previous page");
    setCurrentPage(currentPage - 1);
    setRefresh(!refresh)
  }
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    // console.log("next page")
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Roadmap Posts
                </MDTypography>
              </MDBox>
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography variant="h6" fontWeight="medium">

                </MDTypography>
                <MDButton variant="gradient" color="dark" onClick={handleAddNewAuthor}>
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp;add new post
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                {/* Render your DataTable component here */}
                <DataTable
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25],
                  }}
                  HandlePreviousPage={handlePreviousPage}
                  handleNextPage={handleNextPage}
                  currentDataPage={currentDataPage}
                  totalDoc={totalDoc}
                  totalPages={totalPages}

                  canSearch={true}
                  showTotalEntries={true}
                  table={tableData}
                  pagination={{ variant: "gradient", color: "info" }}
                  isSorted={true}
                  noEndBorder={false}
                />
                <MDPagination />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
