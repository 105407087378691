import React, { useState } from 'react'
import sugar from "assets/images/sugar.png";
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from "yup";
import BasicLayout from '../components/BasicLayout';
import { Card } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import './Otp.css';
// import { adminVerifyLogin } from "../../../store/AdminSlices/Admin";
import { adminVerifyLogin } from "../../../store/AdminSlices/Admin";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';




const Otp = () => {
    const { state } = useLocation();
    const navigate = useNavigate()
    // console.log("---state", state)
    const dispatch = useDispatch();
    const otpdata = useSelector((state) => state)
    const [otpValues, setOtpValues] = useState(['', '', '', '', '', '']);
    const [error, setError] = useState('');
    const initialValues = {
        nonce: ""
    };
    const validationSchema = Yup.object().shape({
        nonce: Yup.string().required("OTP is required")
    });
    const handleOtpChange = (e, index) => {
        const { value } = e.target;
        // Use a regular expression to match a single digit (0-9)
        if (/^[0-9]$/.test(value)) {
            if (value.length === 1 && index < 5) {
                // If a digit is entered and it's not the last input field, focus on the next field
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
            const newOtpValues = [...otpValues];
            newOtpValues[index] = value;
            setOtpValues(newOtpValues);
        }
    };
    // console.log('Submitted OTP--', otpdata);

    const handleSubmit = async (values) => {
       

        // Check if any field is empty
        // if (otpValues.some(value => value === '')) {
            // setError('All OTP fields are required.');
        // } else {
            // setError('');
            // const otp = otpValues.join('');
            let data = { nonce: values.nonce, ...state };
            const val = await dispatch(adminVerifyLogin(data));

            // console.log('Submitted OTP:', val);
            if (val?.payload?.success == true) {
                setTimeout(() => {
                    localStorage.setItem("sugarToken", val.payload.result.authToken);
                    navigate("/posts")
                }, 1500)
            // }
        }
    };
    return (
        <div>
            <BasicLayout image={sugar}>
                <Card>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={3}
                        mb={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                            Verify OTP
                        </MDTypography>
                        <MDTypography display="block" variant="button" color="white" my={1}>
                            Enter the 6-digit OTP sent to your email
                        </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ errors, touched, setFieldValue }) => {
                                return (
                                    <Form>

                                        <MDBox display="flex" justifyContent="space-between" mb={2} >
                                            <Field
                                                type="text"
                                                name="nonce"
                                                label="OTP"
                                                fullWidth
                                                component={MDInput}
                                            />
                                        </MDBox>
                                            <ErrorMessage name="nonce" component="span" className="error" style={{ fontSize: "12px" }} />
                                        <MDBox mt={4} mb={1}>
                                            <MDButton type="submit" variant="gradient" color="info" fullWidth>
                                                Verify OTP
                                            </MDButton>
                                        </MDBox>
                                    </Form>
                                );
                            }}
                        </Formik>
                        {/* <MDBox mt={3} mb={1} textAlign="center">
                            <MDTypography variant="button" color="text">
                                <MDTypography>
                                </MDTypography>
                                <MDTypography
                                    component="a"
                                    href="#"
                                    variant="button"
                                    color="info"
                                    textGradient
                                >
                                    Resend OTP
                                </MDTypography>
                            </MDTypography>
                        </MDBox> */}

                    </MDBox>
                </Card>

            </BasicLayout>
        </div>
    )
}

export default Otp