import React, { useState } from 'react';
import { Box, Card, Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { adminCreateContract } from '../../store/AdminSlices/Admin';
import { useDispatch, useSelector } from 'react-redux';
import MDTypography from 'components/MDTypography';
import './AddContract.css'
import { Navigate, useNavigate } from 'react-router-dom';

const AddContract = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [address, setAddress] = useState('');
    const [file, setFile] = useState('');
    const [contract, setContract] = useState('');
    const [errors, setErrors] = useState({
        address: '',
        file: '',
        contract: '',
    });

    const onSubmit = async (e) => {
        e.preventDefault();

        // Custom validation: Check if all required fields are filled.
        const newErrors = {
            address: '',
            file: '',
            contract: '',
        };

        if (!address) {
            newErrors.address = 'Address is required.';
        }

        if (!file && !contract) {
            newErrors.file = 'File is required.';
            newErrors.contract = 'Contract is required.';
        }

        // Check for errors and display appropriate messages
        if (newErrors.address || (newErrors.file && newErrors.contract)) {
            setErrors(newErrors);
        } else {
            if (file) {
                // console.log("file", file);
                const formData = new FormData();
                formData.append('address', address);
                formData.append('file', file);
                dispatch(adminCreateContract(formData)).then((res)=>{
                    console.log("Res", res?.payload?.success);
                    if(res?.payload?.success === true) {
                        navigate('/contracts')
                    }
                })
            }
            else {
                // console.log("No File", contract, address);
                let data = {
                    "address": address,
                    "abi": contract
                }
                dispatch(adminCreateContract(data)).then((res)=>{
                    console.log("Res", res?.payload?.success);
                    if(res?.payload?.success === true) {
                        navigate('/contracts')
                    }
                })
            }
            setErrors({
                address: '',
                file: '',
                contract: '',
            });
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar absolute isMini />
            <MDBox
                // my={3} 
                pt={6}
            >
                <form onSubmit={onSubmit}>
                    <MDBox>
                        <Grid
                            container
                            display="flex"
                            alignItems="center"
                            justifyContent="center"

                        >
                            <Grid item xs={12} >
                                <Card >
                                    <Grid>
                                        <Grid>
                                            <Grid>
                                                <Grid
                                                    container
                                                    width="100%"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    py={4}
                                                    px={4}

                                                >
                                                    <Grid width="100%">
                                                        <Grid my={1}>
                                                            <Grid container>
                                                                <MDInput
                                                                    type="text"
                                                                    name="address"
                                                                    label="Contract Address"
                                                                    fullWidth
                                                                    value={address}
                                                                    onChange={(e) => setAddress(e.target.value)}
                                                                />
                                                                {errors.address && <div>{errors.address}</div>}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid my={3}>
                                                            <hr />
                                                        </Grid>
                                                        <Grid container alignItems="center" >
                                                        <Grid item lg={3} >
                                                            <Grid container>
                                                                <input
                                                                    type="file"
                                                                    name="file"
                                                                    placeholder='adfasdfasdf'
                                                                    onChange={(e) => setFile(e.target.files[0])}
                                                                />
                                                                {errors.file && <div>{errors.file}</div>}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item lg={1}>
                                                            <MDTypography className="line"> Or </MDTypography>
                                                        </Grid>
                                                        <Grid item lg={8}>
                                                            <Grid container>
                                                                <MDInput
                                                                    type="text"
                                                                    name="contract"
                                                                    label="Contract ABI"
                                                                    multiline
                                                                    fullWidth
                                                                    rows={4}
                                                                    value={contract}
                                                                    onChange={(e) => setContract(e.target.value)}
                                                                />
                                                                {errors.contract && <div>{errors.contract}</div>}
                                                            </Grid>
                                                        </Grid>
                                                        </Grid>
                                                        <Grid my={1}>
                                                            <Grid container display="flex" justifyContent="flex-end">
                                                                <MDButton variant="gradient" type="submit" color="info">
                                                                    Submit
                                                                </MDButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </form>
            </MDBox>
        </DashboardLayout>
    );
};

export default AddContract;
