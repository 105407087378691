import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
const ProtectedRoute = (props) => {
  // console.log("groups :")
  const { Component } = props;


  
  const navigate = useNavigate();
  useEffect(() => {
    var x = localStorage.getItem("sugarToken");
    if (x === undefined || x == null) {
      // console.log("note toke")
      navigate("/authentication/sign-in");
    }
  });
  return (
    <div>
      <Component />
    </div>
  );
};

export default ProtectedRoute;
