import React, { useEffect, useRef, useState } from "react";
import { Box, Card, Grid, Typography } from '@mui/material'
import { Field, Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import MDBox from 'components/MDBox'
import MDInput from 'components/MDInput'
import MDButton from "components/MDButton";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Icon from "@mui/material/Icon";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

// import PostImage from '../../assets/images/post-cover.png';
import PostImage from '../../../assets/images/post-cover.png';

// import './AddAuthor.css';
// import {adminCreatePost} from '../../store/AdminSlices/Admin';
// import { adminCreatePost, adminGetPostById } from "../../store/AdminSlices/Admin";
import { adminUpdateTeam, adminGetTeamById } from "../../../store/AdminSlices/Admin";

import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MDTypography from "components/MDTypography";
// import Cropper from "../../layouts/utils/Cropper";
import Cropper from "../../../layouts/utils/Cropper";



const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    twitterLink: Yup.string().required('Link is required'),
    // file: Yup.mixed().required('Image is required'),
});
const EditTeam = () => {
    const { id } = useParams();
    const [post, setPost] = useState(null)
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [showCroppedImage, setShowCroppedImage] = useState(null);
    const [profileImageSrc, setProfileImageSrc] = useState(null);
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [newsImg, setNewsImg] = useState(null);
    const [open, setOpen] = useState(false);




    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(adminGetTeamById(id));
                // console.log("post", response?.payload?.result?.team)
                // setPosts(response)
                setPost(response.payload.result.team);
            } catch (error) {
                // Handle any errors here
                console.error("Error fetching posts:", error);
            }
        };

        fetchData();
    }, [dispatch]);



    const initialValues = {
        name: post ? post?.name : '',
        twitterLink: post ? post?.twitterLink : "",
        file: "",
    };
    const onSubmit = (values) => {
        // Handle form submission here
        // console.log('Form submitted with values:', values);
        if (selectedImage) {
            // console.log("image", selectedImage);
            // console.log("asdfsdf", values)
            const formData = new FormData();
            formData.append("teamId", id)
            formData.append('name', values.name);
            formData.append('twitterLink', values.twitterLink);
            formData.append('file', newsImg);
            dispatch(adminUpdateTeam(formData)).then((res)=>{
                // console.log("res", res)
                if(res?.payload?.success === true) {
                    navigate("/members")
                }
            });

        }
        else {
            // console.log("no image")
            let data = values;
            data.teamId = id
            delete data.file;
            dispatch(adminUpdateTeam(data)).then((res)=>{
                if(res?.payload?.success === true) {
                    navigate("/members")
                }
            });;
            // dispatch(adminCreatePost(data));
        }
        // dispatch(adminCreatePost(values));
    };
    const [crop, setCrop] = useState(false);
    const [crope, setCrope] = useState();
    const hiddenFileInput = useRef(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    let formik;
    const handleClose = () => setOpen(false);




    return (

        <div>
            <DashboardLayout>
                <DashboardNavbar absolute isMini />
                <MDBox my={3} pt={6}>
                    {
                        post ?
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {({ errors, touched, setFieldValue }) => {
                                    // Store the formik context in a variable for later use
                                    formik = { setFieldValue };
                                    return (
                                        <Form>
                                            <MDBox>
                                                <Grid container display="flex" alignItems="center" justifyContent="center">
                                                    <Grid item xs={12}>
                                                        <Card>
                                                            <Grid>
                                                                <Grid container
                                                                    width="100%"
                                                                    display="flex"
                                                                    alignItems="center"
                                                                    justifyContent="space-between"
                                                                    py={4}
                                                                    px={4}
                                                                >
                                                                    <Grid item xs={6} md={6} sm={6} lg={2}>
                                                                        <Box
                                                                            position="relative"
                                                                            // overflow="hidden"
                                                                            display="inline-block"
                                                                            width={200}
                                                                        // sx={{ cursor: "pointer"  }}
                                                                        >
                                                                            <Box
                                                                                component="img"
                                                                                src={
                                                                                    profileImageSrc && !showCroppedImage
                                                                                        ? profileImageSrc
                                                                                        : showCroppedImage
                                                                                            ? showCroppedImage
                                                                                            : post?.fileName ? `${process.env.REACT_APP_IMG_URL}/${post?.fileName}` : ""
                                                                                }
                                                                                id="newsImage"
                                                                                alt="uploadImg"
                                                                                sx={{
                                                                                    height: "200px",
                                                                                    width: "175px",
                                                                                    borderRadius: "10px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            />
                                                                            <input
                                                                                type="file"
                                                                                accept={"image/*"}
                                                                                style={{
                                                                                    opacity: 0,
                                                                                    position: "absolute",
                                                                                    top: 0,
                                                                                    left: 0,
                                                                                    width: "100%",
                                                                                    height: "100%",
                                                                                    cursor: "pointer"
                                                                                }}
                                                                                onChange={(event) => {
                                                                                    setSelectedImage(event?.currentTarget?.files[0]);
                                                                                    let file = event?.currentTarget?.files[0];
                                                                                    if (event.target.files.length === 1) {
                                                                                        setOpen(true);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                        {open && (
                                                                            <Box sx={Boxstyle}>
                                                                                <Typography
                                                                                    style={{
                                                                                        color: "#333333",
                                                                                        fontFamily: "OpenSans",
                                                                                        fontSize: "20px",
                                                                                        width: "100%",
                                                                                    }}
                                                                                    pb={2}
                                                                                >
                                                                                    Edit Team Image
                                                                                </Typography>
                                                                                <Cropper
                                                                                    name="profilePicture"
                                                                                    formik={setFieldValue}
                                                                                    onClose={handleClose}
                                                                                    setShowCroppedImage={setShowCroppedImage}
                                                                                    selectedImage={selectedImage}
                                                                                    setFile={setNewsImg}
                                                                                />
                                                                            </Box>
                                                                        )}
                                                                        <ErrorMessage name="file" component="span" className="error" style={{ fontSize: '12px' }} />
                                                                    </Grid>
                                                                    <Grid item xs={6} md={6} sm={6} lg={10}>
                                                                        <Grid ml={4} my={1}>
                                                                            <Grid container>
                                                                                <Field
                                                                                    type="text"
                                                                                    name="name"
                                                                                    as={MDInput}
                                                                                    label="Name"
                                                                                    fullWidth
                                                                                />
                                                                                <ErrorMessage name="name" component="span" className="error" style={{ fontSize: "12px" }} />
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid ml={4} my={1} >
                                                                            <Grid container>
                                                                                <Field
                                                                                    type="text"
                                                                                    name="twitterLink"
                                                                                    as={MDInput}
                                                                                    label="Link"
                                                                                    fullWidth
                                                                                />
                                                                                <ErrorMessage name="twitterLink" component="span" className="error" style={{ fontSize: "12px" }} />
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid >
                                                                            <Grid container display="flex" justifyContent="flex-end">
                                                                                <MDButton type="submit" color="info">Submit</MDButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                            </Grid>



                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </MDBox>
                                        </Form>
                                    );
                                }}
                            </Formik> : <Grid>
                                Loading...
                            </Grid>
                    }
                </MDBox>
            </DashboardLayout>
        </div>
    )
}


const Boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "300px",
    height: "420px",
    border: "2px solid #1D9BF0",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    py: 2,
    px: 4,
    zIndex: "9999999999"
};

export default EditTeam