

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useDispatch } from "react-redux";
import {setSharedState } from "../../../../store/AdminSlices/Functions"

import { adminDeleteContract, adminDeleteTeam } from "../../../../store/AdminSlices/Admin"

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import MDAvatar from "components/MDAvatar";

function TeamsCard({ name, file, email, id, noGutter, }) {

  // console.log("---",name, file, email)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const location =  window.location.pathname 


  const postEdit = (id) => {
    // console.log("edit", id);
    if(location == "/members"){
      navigate(`/edit-member/${id}`)
    }
    else {
      navigate(`/edit-contract/${id}`)
    }
  }

  const viewPost = (id)=>{
    if(location == "/members") {
      navigate(`/member/${id}`)
    }
    else {
      navigate(`/contract/${id}`)
    }
  }

  const handleDelete = (id) => {
    if(location == "/members") {
    Swal.fire({
      title: 'Are you sure you want to delete the member?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(adminDeleteTeam(id));
        dispatch(setSharedState());
      } else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }
  else {
    // console.log("delete", id);
    Swal.fire({
      title: 'Are you sure you want to delete the contract?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(adminDeleteContract(id));
        dispatch(setSharedState());
      } else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }
  }
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {
        image ? <MDAvatar src={image} name={name} size="sm" /> :""
      }
      
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
      mx={2}
    >
      <MDBox width="100%" display="flex" flexDirection="row-reverse" justifyContent="space-between">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
          
        >
          {/* <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {name}
          </MDTypography> */}

          <MDBox  display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <MDBox mr={1}>
              <MDButton onClick={() => handleDelete(id)} variant="text" color="error">
                <Icon>delete</Icon>&nbsp;delete
              </MDButton>
            </MDBox>
            <MDButton onClick={() => postEdit(id)} variant="text" color={darkMode ? "white" : "dark"}>
              <Icon>edit</Icon>&nbsp;edit
            </MDButton>
            <MDButton onClick={() => viewPost(id)} variant="text" color={darkMode ? "white" : "dark"}>
              <Icon>visibility</Icon>&nbsp;view
            </MDButton>
          </MDBox>
        </MDBox>
        <Author  image={file ?  `${process.env.REACT_APP_IMG_URL}/${file}`:""} name={name} email={email} />
        {/* <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Title:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
              {company}
            </MDTypography>
          </MDTypography>
        </MDBox> */}
        
        
        {/* <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Twitter:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {link}
            </MDTypography>
          </MDTypography>
        </MDBox> */}



        {/* <MDTypography variant="caption" color="text">
          VAT Number:&nbsp;&nbsp;&nbsp;
          <MDTypography variant="caption" fontWeight="medium">
            {vat}
          </MDTypography>
        </MDTypography> */}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Bill
TeamsCard.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
TeamsCard.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  vat: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default TeamsCard;
