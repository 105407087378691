// storageSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  signIn,
  verifyLogin,
  createPost,
  getPosts,
  deletePost,
  getPostById,
  createTeam,
  getTeams,
  deleteTeam,
  getTeamById,
  forgetPassword,
  resetPassword,
  updatePost,
  updateTeam,
  getAllContracts,
  createContract,
  deleteContract,
  contractById,
  updateContractById
} from '../../services/Admin';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { Navigate, useNavigate } from 'react-router-dom';





export const adminSignIn = createAsyncThunk(
  'admin/sign-in',
  async (data, { rejectWithValue }) => {
    try {
      const response = await signIn(data);
      if (response.status == 200 & response.data.success == true) {
        Swal.fire({
          icon: 'success',
          title: response.data.result.message,
          showConfirmButton: false,
          timer: 1500
        })
      }
      if (response.status == 400) {
        // console.log("response", response);

      }
      return response.data;
    } catch (err) {
      // console.error('Network Error:', err.response.data.result.details.MESSAGE);
      if (err.response.status === 400) {
        toast.error(err?.response?.data?.result?.details?.MESSAGE)
      }
    }

  }
);

export const adminVerifyLogin = createAsyncThunk(
  'admin/verify-login',
  async (data, { rejectWithValue }) => {
    try {
      // console.log("redu data:", data)
      const response = await verifyLogin(data);
      if (response.status == 200 & response.data.success == true) {
        toast.success(response.data.result.message)
        return response.data;
      }
    } catch (err) {
      // console.error('Network Error:', err.response.data.result.details.MESSAGE);
      if (err.response.status === 400) {
        // Swal.fire({
        //   icon: 'error',
        //   title: 'Error',
        //   text: err?.response?.data?.result?.details?.MESSAGE, // Adjust this based on your API response structure
        //   showConfirmButton: true
        // });
        toast.error(err?.response?.data?.result?.details?.MESSAGE)
      }
    }
  }
);

export const adminCreatePost = createAsyncThunk(
  'admin/create-post',
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("sugarToken")
      const response = await createPost(data);
      // console.log("response", response);
      if (response.status == 200 & response.data.success == true) {
        // console.error('Network Error:', response);
        toast.success('Post Created')
      }

      return response.data;
    } catch (err) {
      console.error('Network Error:', err.response.data.result.details.MESSAGE);
      if (err.response.status === 400) {
        // console.log("inavalid", err.response.data.result?.details.MESSAGE)
        if(err.response.data.result?.details.MESSAGE) {
          toast.error(err.response.data.result?.details.MESSAGE)
        }if(err?.response?.data?.result?.MESSAGE){
          // toast.error(err?.response?.data?.result?.MESSAGE);
        }
      } if (err.response.status === 401) {
        toast.error(err?.response?.data?.result?.MESSAGE);
        setTimeout(() => {
          localStorage.clear();
          location.reload();
        }, 3000);
      }
    }
  }
)

export const adminCreateTeam = createAsyncThunk(
  'admin/create-team',
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("sugarToken")
      const response = await createTeam(data);
      // console.log("response", response);
      if (response.status == 200 & response.data.success == true) {
        // Swal.fire({
        //   icon: 'success',
        //   title: response.data.result.message,
        //   showConfirmButton: true,
        //   timer: 1500
        // })
        toast.success("Team Created")
      }
      return response.data;
    } catch (err) {
      console.error('Network Error:', err.response.data.result.details.MESSAGE);
      if (err.response.status === 400) {
      console.log("Ree", err.response?.data?.result?.MESSAGE)
      if(err.response?.data?.result?.details == "Cannot read properties of undefined (reading 'mimetype')"){
        toast.error(err.response?.data?.result?.details)
      }
      else{
        toast.error(err?.response?.data?.result?.details?.MESSAGE)
      }
      } if (err.response.status === 401) {
        toast.error(err?.response?.data?.result?.MESSAGE);
        setTimeout(() => {
          localStorage.clear();
          location.reload();
        }, 3000);
      }
    }
  }
)


export const adminDeletePost = createAsyncThunk(
  'admin/delete-post',
  async (data, { rejectWithValue }) => {
    try {

      const response = await deletePost(data);
      if (response.status == 200 & response.data.success == true) {

        toast.success("Post Deleted")
      }
      return response.data;
    } catch (err) {
      console.error('Network Error:', err.response.data.result.details.MESSAGE);
      if (err.response.status === 400) {
        toast.error(err?.response?.data?.result?.details?.MESSAGE)
      } if (err.response.status === 401) {
        toast.error(err?.response?.data?.result?.MESSAGE);
        setTimeout(() => {
          localStorage.clear();
          location.reload();
        }, 3000);
      }
      return rejectWithValue(err.response.data);
    }
  }
)

export const adminDeleteTeam = createAsyncThunk(
  'admin/delete-team',
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteTeam(data);
      if (response.status == 200 & response.data.success == true) {
        // Swal.fire({
        //   icon: 'success',
        //   title: response.data.result.message,
        //   showConfirmButton: true,
        //   timer: 1500
        // })
        toast.success("Member Deleted")
      }

      return response.data;
    }
    catch (err) {
      // console.error('Network Error:', err.response.data.result.details.MESSAGE);
      if (err.response.status === 400) {

        toast.error(err?.response?.data?.result?.details?.MESSAGE)
      } if (err.response.status === 401) {
        toast.error(err?.response?.data?.result?.MESSAGE);
        setTimeout(() => {
          localStorage.clear();
          location.reload();
        }, 3000);
      }
      return rejectWithValue(err.response.data);
    }
  }
)

export const adminDeleteContract = createAsyncThunk(
  'admin/delete-contract',
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteContract(data);
      if (response.status == 200 & response.data.success == true) {
        // Swal.fire({
        //   icon: 'success',
        //   title: response.data.result.message,
        //   showConfirmButton: true,
        //   timer: 1500
        // })
        toast.success("contract Deleted")
      }
      if (response.status == 400) {
      }
      return response.data;
    }
    catch (err) {
      console.error('Network Error:', err.response.data.result.details.MESSAGE);
      if (err.response.status === 400) {
        toast.error(err?.response?.data?.result?.details?.MESSAGE)
      } if (err.response.status === 401) {
        toast.error(err?.response?.data?.result?.MESSAGE);
        setTimeout(() => {
          localStorage.clear();
          location.reload();
        }, 3000);
      }
      return rejectWithValue(err.response.data);
    }
  }
)


export const adminGetPosts = createAsyncThunk(
  'admin/get-posts',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getPosts(data);
      // console.log("response", response)
      return response.data;
    }
    catch (err) {
      console.error('Network Error:', err.response.data.result.details.MESSAGE);
      if (err.response.status === 400) {

        toast.error(err?.response?.data?.result?.details?.MESSAGE)
      } if (err.response.status === 401) {
        toast.error(err?.response?.data?.result?.MESSAGE);
        setTimeout(() => {
          localStorage.clear();
          location.reload();
        }, 3000);
      }
      return rejectWithValue(err.response.data);
    }
  }
)

export const adminGetTeams = createAsyncThunk(
  'admin/get-teams',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getTeams(data);
      // console.log("response", response)
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
)

export const adminGetPostById = createAsyncThunk(
  'admin/get-posts-id',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getPostById(id);
      // console.log("response", response)
      return response.data;
      if (response.status == 200 & response.data.success == true) {
        Swal.fire({
          icon: 'success',
          title: response.data.result.message,
          showConfirmButton: true,
          timer: 1500
        })
      }
    } catch (err) {
      console.error('Network Error:', err.response.data.result.details.MESSAGE);
      if (err.response.status === 400) {

        toast.error(err?.response?.data?.result?.details?.MESSAGE)
      } if (err.response.status === 401) {
        toast.error(err?.response?.data?.result?.MESSAGE);
        setTimeout(() => {
          localStorage.clear();
          location.reload();
        }, 3000);
      }
      return rejectWithValue(err.response.data);
    }
  }
)

export const adminForgetPassword = createAsyncThunk(
  'admin/forget-password',
  async (data, { rejectWithValue }) => {
    try {
      const response = await forgetPassword(data);
      // console.log("response", response)
      if (response.status == 200 & response.data.success == true) {
        // Swal.fire({
        //   icon: 'success',
        //   title: response.data.result.message,
        //   showConfirmButton: true,
        //   timer: 1500
        // });
        toast.success(response.data.result.message)
      }
      return response.data;
    }
    catch (err) {
      console.error('Network Error:', err.response.data.result.details.MESSAGE);
      if (err.response.status === 400) {

        toast.error(err?.response?.data?.result?.details?.MESSAGE)
      }
      return rejectWithValue(err.response.data);
    }
  }
)

export const adminResetPassword = createAsyncThunk(
  'admin/reset-password',
  async (data, { rejectWithValue }) => {
    try {
      const response = await resetPassword(data);
      // console.log("response", response)
      if (response.status == 200 & response.data.success == true) {
        // Swal.fire({
        //   icon: 'success',
        //   title: response.data.result.message,
        //   showConfirmButton: true,
        //   timer: 1500
        // });
        toast.success(response.data.result.message)
      }
      return response.data;
    }
    catch (err) {
      console.error('Network Error:', err.response.data.result.details.MESSAGE);
      if (err.response.status === 400) {

        toast.error(err?.response?.data?.result?.details?.MESSAGE)
      }
      return rejectWithValue(err.response.data);
    }
  }
)


export const adminUpdatePost = createAsyncThunk(
  'admin/update-post',
  async (data, { rejectWithValue }) => {
    try {
      const response = await updatePost(data);
      // console.log("response", response)
      if (response.status == 200 & response.data.success == true) {
        // Swal.fire({
        //   icon: 'success',
        //   title: response.data.result.message,
        //   showConfirmButton: true,
        //   timer: 1500
        // })
        toast.success("Post Updated")
      }
      return response.data;
    }
    catch (err) {
      console.error('Network Error:', err.response.data.result.details.MESSAGE);
      if (err.response.status === 400) {

        toast.error(err?.response?.data?.result?.details?.MESSAGE)
      } if (err.response.status === 401) {
        toast.error(err?.response?.data?.result?.MESSAGE);
        setTimeout(() => {
          localStorage.clear();
          location.reload();
        }, 3000);
      }
      return rejectWithValue(err.response.data);
    }
  }
)

export const adminUpdateContract = createAsyncThunk(
  'admin/update-contract',
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateContractById(data);
      // console.log("response", response)
      if (response.status == 200 & response.data.success == true) {
        // Swal.fire({
        //   icon: 'success',
        //   title: response.data.result.message,
        //   showConfirmButton: true,
        //   timer: 1500
        // })
        toast.success("Contract Updated")
      }
      return response.data;
    }
    catch (err) {
      console.error('Network Error:', err.response.data.result.details.MESSAGE);
      if (err.response.status === 400) {
        toast.error(err?.response?.data?.result?.details?.MESSAGE)
      } if (err.response.status === 401) {
        toast.error(err?.response?.data?.result?.MESSAGE);
        setTimeout(() => {
          localStorage.clear();
          location.reload();
        }, 3000);
      }
      return rejectWithValue(err.response.data);
    }
  }
)

export const adminUpdateTeam = createAsyncThunk(
  'admin/update-team',
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateTeam(data);
      // console.log("response", response)
      if (response.status == 200 & response.data.success == true) {
        // Swal.fire({
        //   icon: 'success',
        //   title: response.data.result.message,
        //   showConfirmButton: true,
        //   timer: 1500
        // })
        // console.log("ASDf", response)
        toast.success("Member updated")
      }
      return response.data;
    }
    catch (err) {
      console.error('Network Error:', err.response.data.result.details.MESSAGE);
      if (err.response.status === 400) {

        toast.error(err?.response?.data?.result?.details?.MESSAGE)
      } if (err.response.status === 401) {
        toast.error(err?.response?.data?.result?.MESSAGE);
        setTimeout(() => {
          localStorage.clear();
          location.reload();
        }, 3000);
      }
      return rejectWithValue(err.response.data);
    }
  }
)

export const adminCreateContract = createAsyncThunk(
  'admin/create-contract',
  async (data, { rejectWithValue }) => {
    try {
      const response = await createContract(data);
      // console.log("response", response)
      if (response.status == 200 & response.data.success == true) {
        // Swal.fire({
        //   icon: 'success',
        //   title: response.data.result.message,
        //   showConfirmButton: true,
        //   timer: 1500
        // })
        // console.log("ASDf", response)
        toast.success("Contract Created")
      }
      return response.data;
    }
    catch (err) {
      console.error('Network Error:', err.response.data.result.details.MESSAGE);
      if (err.response.status === 400) {

        toast.error(err?.response?.data?.result?.details?.MESSAGE)
      } if (err.response.status === 401) {
       
        toast.error(err?.response?.data?.result?.MESSAGE);
        setTimeout(() => {
          localStorage.clear();
          location.reload();
        }, 3000);
      }
      return rejectWithValue(err.response.data);
    }
  }
)

export const adminGetAllContracts = createAsyncThunk(
  'admin/get-contract',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllContracts(data);
      // console.log("response", response)
      if (response.status == 200 & response.data.success == true) {
        // Swal.fire({
        //   icon: 'success',
        //   title: response.data.result.message,
        //   showConfirmButton: true,
        //   timer: 1500
        // })
        // console.log("ASDf", response)
        // toast.success("Contract Created")
      }
      return response.data;
    }
    catch (err) {
      console.error('Network Error:', err.response.data.result.details.MESSAGE);
      if (err.response.status === 400) {

        toast.error(err?.response?.data?.result?.details?.MESSAGE)
      } if (err.response.status === 401) {
        toast.error(err?.response?.data?.result?.MESSAGE);
        setTimeout(() => {
          localStorage.clear();
          location.reload();
        }, 3000);
      }
      return rejectWithValue(err.response.data);
    }
  }
)

export const adminGetTeamById = createAsyncThunk(
  'admin/get-team-id',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getTeamById(id);
      // console.log("response", response)
      return response.data;
    } catch (err) {
      console.error('Network Error:', err.response.data.result.details.MESSAGE);
      if (err.response.status === 400) {

        toast.error(err?.response?.data?.result?.details?.MESSAGE)
      } if (err.response.status === 401) {
        toast.error(err?.response?.data?.result?.MESSAGE);
        setTimeout(() => {
          localStorage.clear();
          location.reload();
        }, 3000);
      }
      return rejectWithValue(err.response.data);
    }
  }
)

export const adminGetContractById = createAsyncThunk(
  'admin/get-contract-id',
  async (id, { rejectWithValue }) => {
    try {
      const response = await contractById(id);
      // console.log("response", response)
      return response.data;
      if (response.status == 200 & response.data.success == true) {
        Swal.fire({
          icon: 'success',
          title: response.data.result.message,
          showConfirmButton: true,
          timer: 1500
        })
      }
    } catch (err) {
      console.error('Network Error:', err.response.data.result.details.MESSAGE);
      if (err.response.status === 400) {

        toast.error(err?.response?.data?.result?.details?.MESSAGE)
      } if (err.response.status === 401) {
        
        toast.error(err?.response?.data?.result?.MESSAGE);
        setTimeout(() => {
          localStorage.clear();
          location.reload();
        }, 3000);
      }
      return rejectWithValue(err.response.data);
    }
  }
)


export const adminSlices = createSlice({
  name: 'admin',

  initialState: {
    data: [],
    loading: false,
    error: null,
    posts: []
  },
  reducers: {},
  extraReducers: (builder) => {



    builder
      .addCase(adminSignIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminSignIn.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(adminSignIn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });


    // ----verify-----

    builder.addCase(adminVerifyLogin.pending, (state) => {
      state.loading = true;
    })
      .addCase(adminVerifyLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(adminVerifyLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
    //----admin create post------

    builder.addCase(adminCreatePost.pending, (state) => {
      state.loading = true;
    })
      .addCase(adminCreatePost.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(adminCreatePost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })


    // --- get posts ---
    builder.addCase(adminGetPosts.pending, (state) => {
      state.loading = true;
    })
      .addCase(adminGetPosts.fulfilled, (state, action) => {
        state.loading = false;
        state.posts = action.payload;
      })
      .addCase(adminGetPosts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })


    // ---delete post ----
    builder.addCase(adminDeletePost.pending, (state) => {
      state.loading = true;
    })
      .addCase(adminDeletePost.fulfilled, (state, action) => {
        state.loading = false;
        state.posts = action.payload;
      })
      .addCase(adminDeletePost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })


    // ---- create team -----
    builder.addCase(adminCreateTeam.pending, (state) => {
      state.loading = true;
    })
      .addCase(adminCreateTeam.fulfilled, (state, action) => {
        state.loading = false;
        state.posts = action.payload;
      })
      .addCase(adminCreateTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

    //----- Teams -----

    builder.addCase(adminGetTeams.pending, (state) => {
      state.loading = true;
    })
      .addCase(adminGetTeams.fulfilled, (state, action) => {
        state.loading = false;
        state.posts = action.payload;
      })
      .addCase(adminGetTeams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

    // ----delete -team ----
    builder.addCase(adminDeleteTeam.pending, (state) => {
      state.loading = true;
    })
      .addCase(adminDeleteTeam.fulfilled, (state, action) => {
        state.loading = false;
        state.posts = action.payload;
      })
      .addCase(adminDeleteTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })


    // reset-password

    builder.addCase(adminResetPassword.pending, (state) => {
      state.loading = true;
    })
      .addCase(adminResetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.posts = action.payload;
      })
      .addCase(adminResetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })


    // admin - forgotpassword 



    builder.addCase(adminForgetPassword.pending, (state) => {
      state.loading = true;
    })
      .addCase(adminForgetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.posts = action.payload;
      })
      .addCase(adminForgetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })


    // admin edit post
    builder.addCase(adminUpdatePost.pending, (state) => {
      state.loading = true;
    })
      .addCase(adminUpdatePost.fulfilled, (state, action) => {
        state.loading = false;
        state.posts = action.payload;
      })
      .addCase(adminUpdatePost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })


    //adminCreateContract
    builder.addCase(adminCreateContract.pending, (state) => {
      state.loading = true;
    })
      .addCase(adminCreateContract.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(adminCreateContract.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })


    //adminGetAllContracts

    builder.addCase(adminGetAllContracts.pending, (state) => {
      state.loading = true;
    })
      .addCase(adminGetAllContracts.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(adminGetAllContracts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

    //adminDeleteContract
    builder.addCase(adminDeleteContract.pending, (state) => {
      state.loading = true;
    })
      .addCase(adminDeleteContract.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(adminDeleteContract.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

    //adminGetContractById
    builder.addCase(adminGetContractById.pending, (state) => {
      state.loading = true;
    })
      .addCase(adminGetContractById.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(adminGetContractById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

    //adminUpdateContract
    builder.addCase(adminUpdateContract.pending, (state) => {
      state.loading = true;
    })
      .addCase(adminUpdateContract.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(adminUpdateContract.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export default adminSlices.reducer;
