// sharedSlice.js
import { createSlice } from '@reduxjs/toolkit';

const sharedSlice = createSlice({
  name: 'shared',
  initialState: {
    sharedState: false,
  },
  reducers: {
    setSharedState: (state, action) => {
        state.sharedState = !state.sharedState;

        
    },
  },
});

export const { setSharedState } = sharedSlice.actions;
export default sharedSlice.reducer;
