import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { adminGetContractById, adminUpdateContract } from "../../store/AdminSlices/Admin"
import { useDispatch } from 'react-redux';
import MDBox from 'components/MDBox';
import { Card, Grid } from '@mui/material';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

const EditContract = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [contracts, setContracts] = useState(null);
    const [address, setAddress] = useState('');
    const [file, setFile] = useState('');
    const [contract, setContract] = useState('');
    const [errors, setErrors] = useState({
        address: '',
        file: '',
        contract: '',
    });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(adminGetContractById(id));
                // console.log("post", response.payload.result.contract?._id)
                // setPosts(response);
                setContracts(response.payload.result.contract);
                setAddress(response.payload.result.contract.address);


            } catch (error) {
                // Handle any errors here
                console.error("Error fetching posts:", error);
            }
        };

        fetchData();

    }, [dispatch, id]);
    const onSubmit = async (e) => {
        e.preventDefault();

        // Custom validation: Check if all required fields are filled.
        const newErrors = {
            address: '',
            file: '',
            contract: '',
        };

        if (!address) {
            newErrors.address = 'Address is required.';
        }

        if (!file && !contract) {
            newErrors.file = 'File is required.';
            newErrors.contract = 'Contract is required.';
        }

        // Check for errors and display appropriate messages
        if (newErrors.address || (newErrors.file && newErrors.contract)) {
            setErrors(newErrors);
        } else {
            if (file) {
                // console.log("file", file);
                const formData = new FormData();
                formData.append('contractId', contracts?._id)
                formData.append('address', address);
                formData.append('file', file);
                dispatch(adminUpdateContract(formData)).then((res)=>{
                    console.log("res", res.payload.success);
                    if(res.payload.success === true) {
                        navigate("/contracts")
                    }
                })
            }
            else {
                // console.log("No File", contract, address);
                let data = {
                    contractId: contracts?._id,
                    address,
                    abi: contract
                }
                dispatch(adminUpdateContract(data)).then((res)=>{
                    console.log("res", res.payload.success);
                    if(res.payload.success === true) {
                        navigate("/contracts")
                    }
                })
            }
            setErrors({
                address: '',
                file: '',
                contract: '',
            });
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6}>

                <form onSubmit={onSubmit}>
                    <MDBox>
                        <Grid
                            container
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item xs={12}>
                                <Card>
                                    <Grid>
                                        <Grid>
                                            <Grid>
                                                <Grid
                                                    container
                                                    width="100%"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    py={4}
                                                    px={4}
                                                >
                                                    <Grid width="100%">
                                                        <Grid my={1}>
                                                            <Grid container>
                                                                <MDInput
                                                                    type="text"
                                                                    name="address"
                                                                    label="Contract Address"
                                                                    fullWidth
                                                                    value={address}
                                                                    onChange={(e) => setAddress(e.target.value)}
                                                                />
                                                                {errors.address && <div>{errors.address}</div>}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid my={3}>
                                                            <hr />
                                                        </Grid>
                                                        <Grid container alignItems="center" >
                                                            <Grid item lg={3}>
                                                                <Grid my={1}>
                                                                    <Grid container>
                                                                        <input
                                                                            type="file"
                                                                            name="file"
                                                                            onChange={(e) => setFile(e.target.files[0])}
                                                                        />
                                                                        {errors.file && <div>{errors.file}</div>}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item lg={1}>
                                                                <MDTypography className="line"> Or </MDTypography>
                                                            </Grid>
                                                            <Grid item lg={8}>
                                                                <Grid my={1}>
                                                                    <Grid container>
                                                                        <MDInput
                                                                            type="text"
                                                                            name="contract"
                                                                            label="Contract"
                                                                            multiline
                                                                            fullWidth
                                                                            rows={4}
                                                                            value={contract}
                                                                            onChange={(e) => setContract(e.target.value)}
                                                                        />
                                                                        {errors.contract && <div>{errors.contract}</div>}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container display="flex" justifyContent="flex-end">
                                                        <MDButton variant="gradient" type="submit" color="info">
                                                            Submit
                                                        </MDButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </form>
            </MDBox>
        </DashboardLayout>
    )
}

export default EditContract