
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import AddAuthor from "layouts/add-author/AddAuthor";
import Otp from "layouts/authentication/otp/Otp";
import EditPost from "layouts/EditPost/EditPost";
import Post from "layouts/post/Post";
import CreateTeam from "layouts/create-team/CreateTeam";
import Teams from "layouts/teams/Teams";
import Team from "layouts/team/Team";
import EditTeam from "layouts/authentication/editteam/EditTeam";
import ForgetPassword from "layouts/ForgetPassword/Forgetpassword";
import ResetPassword from "layouts/ResetPassword/ResetPassword";
import Contracts from "layouts/contracts/Contracts";
import AddContract from "layouts/add-contract/AddContract";
import Contract from "layouts/contract/Contract";
import EditContract from "layouts/EditContract/EditContract";


const routes = [
  {
    // type: "collapse",
    // name: "Dashboard",
    // key: "dashboard",
    // icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Roadmap Posts",
    key: "posts",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/posts",
    component: <Tables />,
  },
  {
    // type: "collapse",
    name: "Add Author",
    key: "Add Author",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/add-post",
    component: <AddAuthor />,
  },
  {
    // type: "collapse",
    // name: "Add Author",
    key: "Add Author",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/edit-post/:id",
    component: <EditPost />,
  },

  {
    // type: "collapse",
    // name: "Add Author",
    // key: "Add Author",
    // icon: <Icon fontSize="small">table_view</Icon>,
    route: "/authentication/verify-login",
    component: <Otp />,
  },
  {
    // type: "collapse",
    // name: "Add Author",
    // key: "Add Author",
    // icon: <Icon fontSize="small">table_view</Icon>,
    route: "/post/:id",
    component: <Post />,
  },
  {
    // type: "collapse",
    // name: "Add Author",
    // key: "Add Author",
    // icon: <Icon fontSize="small">table_view</Icon>,
    route: "/member/:id",
    component: <Team />,
  },
  {
    // type: "collapse",
    // name: "Billing",
    // key: "billing",
    // icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    // type: "collapse",
    // name: "create-team",
    // key: "create-team",
    // icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/create-member",
    component: <CreateTeam/>,
  },
  {
    // type: "collapse",
    // name: "create-team",
    // key: "create-team",
    // icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/add-contract",
    component: <AddContract/>,
  },

  {
    type: "collapse",
    name: "Team Members",
    key: "members",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/members",
    component: <Teams/>,
  },
  {
    type: "collapse",
    name: "Contracts",
    key: "contracts",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/contracts",
    component: <Contracts/>,
  },
  {
    // type: "collapse",
    // name: "teams",
    // key: "teams",
    // icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/edit-member/:id",
    component: <EditTeam/>,
  },
  {
    // type: "collapse",
    // name: "teams",
    // key: "teams",
    // icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/edit-contract/:id",
    component: <EditContract/>,
  },
  {
    // type: "collapse",
    // name: "teams",
    // key: "teams",
    // icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/contract/:id",
    component: <Contract/>,
  },
  {
    // type: "collapse",
    // name: "teams",
    // key: "teams",
    // icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/forget-password",
    component: <ForgetPassword/>,
  },
  {
    // type: "collapse",
    // name: "teams",
    // key: "teams",
    // icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/reset-password",
    component: <ResetPassword/>,
  },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  
  // {
  //   // type: "collapse",
  //   // name: "Profile",
  //   // key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    // type: "collapse",
    // name: "Sign In",
    // key: "sign-in",
    // icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  }
];

export default routes;
